import React from "react"
import { func, node } from "prop-types"
import styled from "styled-components"
import { colors } from "../theme"
import { body1 } from "./global-styles"

const FormContainer = styled.div`
  input,
  textarea {
    ${body1};
    border-radius: 0;
    background-color: transparent;
    border: none;
    border-bottom: solid 1px ${colors.darkText};
    width: 100%;
    color: ${colors.darkText};
    font-size: 16px;
    &::placeholder {
      color: ${colors.darkText};
    }
    &:focus: {
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
`

const Form = ({ onSubmit, children }) => {
  return (
    <FormContainer>
      <form className="sg-form" autoComplete="off" onSubmit={onSubmit}>
        {children}
      </form>
    </FormContainer>
  )
}

Form.propTypes = {
  onSubmit: func,
  children: node,
}

export default Form
