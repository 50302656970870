import React from "react"
import styled from "styled-components"
import { body1_Emphasis } from "./global-styles"
import { breakpoints } from "../theme"

const Container = styled.div`
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  @media (min-width: ${breakpoints.medium}) {
    flex-direction: row;
    align-items: flex-start;
  }
`

const Label = styled.p`
  ${body1_Emphasis};
  margin-bottom: 6px;
  padding-top: 2px;
`

const Input = styled.input`
  @media (min-width: ${breakpoints.medium}) {
    flex: 1;
    margin-left: 10px;
  }
`

const FormInput = ({ label, placeholder, ...rest }) => {
  return (
    <Container>
      <Label>{label}</Label>
      <Input placeholder={placeholder} {...rest} />
    </Container>
  )
}

export default FormInput
