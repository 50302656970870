import React, { useState } from "react"
import styled, { useTheme } from "styled-components"
import { navigate, graphql, useStaticQuery } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout"
import PageWrapper from "../../components/page-wrapper"
import Form from "../../components/form"
import FormInput from "../../components/form-input"
import FormTextArea from "../../components/form-text-area"
import { sendEmail } from "../../server/server"
import { title1 } from "../../components/global-styles"
import IntlText from "../../components/intl-text"
import FormButton from "../../components/form-button"
import { breakpoints } from "../../theme"
import { CONTACT_US_ALT } from "../../constants/Constants"
import "./contact.scss"

const Title = styled.h1`
  ${title1};
  margin-bottom: 56px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 35px;
  }
  @media (min-width: ${breakpoints.extralarge}) {
    margin-bottom: 55px;
  }
`

const Container = styled.div`
  margin-bottom: 140px;
  @media (min-width: ${breakpoints.medium}) {
    margin-bottom: 90px;
  }
`

const ButtonContainer = styled.div`
  margin-top: 25px;
  @media (min-width: ${breakpoints.medium}) {
    margin-top: 56px;
  }
`

const Contact = ({ intl }) => {
  const [values, setValues] = useState({
    name: "",
    company: "",
    projectDesc: "",
    email: "",
  })

  const { isDark } = useTheme();
  const data = useStaticQuery(graphql`
    query imageContact {
      allFile(filter: {base: {eq: "contact-form.png"}}) {
        edges {
          node {
            id
            base
            relativePath
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED,
                  formats: [WEBP]
                )
              }
          }
        }
      }
    }
  `);

  const filterImage = data.allFile.edges.filter((i) => i.node.relativePath.includes(isDark ? "dark" : "light"))

  const buttonDisabled =
    !values.name || !values.company || !values.projectDesc || !values.email

  const handleSubmit = e => {
    e.preventDefault()
    const params = {
      name: values.name,
      company: values.company,
      projectDesc: values.projectDesc,
      email: values.email,
    }
    sendEmail(params)
    navigate("/contact/thank-you", { state: { name: values.name } });
  }

  return (
    <Layout
      hideContact
      pageTitle="Contact"
      title="Looking for a web developer near you? Contact us! - Sysgarage"
      description="If you have an idea in your mind and you want a web developer near you step by step, contact us now and start working together."
    >
      <Container>
        <PageWrapper>
          <div className="row">
            <div className="col-12 col-md-7 col-xl-5">
              <Title>
                <IntlText id="pages.contact.title" />
              </Title>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center align-items-start col-md-4">
              <GatsbyImage
                objectFit="contain"
                className="image-contact-index"
                image={getImage(filterImage[0].node.childImageSharp)}
                alt={CONTACT_US_ALT} />
            </div>
            <div className="col-12 col-md-8">
              <Form>
                <FormInput
                  label={intl.formatMessage({ id: "pages.contact.name.label" })}
                  placeholder={intl.formatMessage({
                    id: "pages.contact.name.placeholder",
                  })}
                  name="name"
                  value={values.name}
                  onChange={e => setValues({ ...values, name: e.target.value })}
                />
                <FormInput
                  label={intl.formatMessage({
                    id: "pages.contact.company.label",
                  })}
                  placeholder={intl.formatMessage({
                    id: "pages.contact.company.placeholder",
                  })}
                  name="company"
                  value={values.company}
                  onChange={e =>
                    setValues({ ...values, company: e.target.value })
                  }
                />
                <FormTextArea
                  label={intl.formatMessage({
                    id: "pages.contact.project.label",
                  })}
                  placeholder={intl.formatMessage({
                    id: "pages.contact.project.placeholder",
                  })}
                  name="projectDesc"
                  value={values.projectDesc}
                  onChange={e =>
                    setValues({ ...values, projectDesc: e.target.value })
                  }
                />
                <FormInput
                  label={intl.formatMessage({
                    id: "pages.contact.email.label",
                  })}
                  placeholder={intl.formatMessage({
                    id: "pages.contact.email.placeholder",
                  })}
                  name="email"
                  value={values.email}
                  onChange={e =>
                    setValues({ ...values, email: e.target.value })
                  }
                  type="email"
                  className="last"
                />
                <ButtonContainer className="d-flex justify-content-center justify-content-md-end">
                  <FormButton
                    size="big"
                    disabled={buttonDisabled}
                    onClick={handleSubmit}
                  >
                    Send
                  </FormButton>
                </ButtonContainer>
              </Form>
            </div>
          </div>
        </PageWrapper>
      </Container>
    </Layout>
  )
}

export default injectIntl(Contact)
