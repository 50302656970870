import React from "react"
import styled from "styled-components"
import { body1_Emphasis } from "./global-styles"

const TextArea = styled.textarea`
  width: 100%;
  margin-bottom: 32px;
`

const Label = styled.p`
  ${body1_Emphasis};
  margin-bottom: 6px;
  padding-top: 2px;
`

const FormTextArea = ({ label, placeholder, ...rest }) => {
  return (
    <div>
      <Label>{label}</Label>
      <TextArea row="3" placeholder={placeholder} {...rest} />
    </div>
  )
}

export default FormTextArea
