import * as emailjs from "emailjs-com"

export const sendEmail = (params, successCb, errorCb) => {
  const { name, company, projectDesc, email } = params

  let templateParams = {
    from_name: name || "n/a",
    from_company: company || "n/a",
    project_desc: projectDesc || "n/a",
    contact_email: email || "n/a",
  }

  emailjs
    .send(
      process.env.GATSBY_EMAILJS_SERVICE_ID,
      process.env.GATSBY_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.GATSBY_EMAILJS_USER_ID
    )
    .then(response => {
      successCb && successCb()
    })
    .catch(error => {
      console.error("Error sending message: ", error)
      errorCb && errorCb()
    })
}
